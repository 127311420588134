<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">Consider the information provided in the table below:</p>

      <v-simple-table class="mb-5" style="max-width: 391px" dense>
        <thead>
          <tr>
            <th style="vertical-align: middle; font-size: 14px"></th>
            <th style="vertical-align: middle; text-align: center; font-size: 14px">
              <stemble-latex content="$\Delta\text{H}_\text{f}^\circ$" />
              <stemble-latex content="$\text{ (kJ/mol)}$" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 14px">
              <stemble-latex content="$\Delta\text{S}^\circ_\text{f}$" />
              <stemble-latex content="$\text{ (J/K}\cdot\text{mol)}$" />
            </th>
          </tr>
        </thead>
        <!-- Body of the table -->
        <tbody>
          <tr>
            <td style="vertical-align: middle; font-size: 15px">
              <stemble-latex :content="molecule" />
            </td>
            <td style="vertical-align: middle; text-align: center; font-size: 13px">
              <stemble-latex :content="deltaH" />
            </td>
            <td style="vertical-align: middle; text-align: center; font-size: 13px">
              <stemble-latex :content="deltaS" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-2">
        a) Write the balanced equation for the dissociation of
        <stemble-latex :content="molecule" />
        into its constituent elements in their standard form (i.e. the opposite of its formation
        reaction).
      </p>

      <chemical-notation-input
        v-model="inputs.balancedEquation"
        dense
        :show-note="false"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) Determine the value of
        <stemble-latex content="$\Delta\text{G}^\circ_{\text{rxn}}$" /> for the above reaction.
      </p>

      <calculation-input
        v-model="inputs.deltaG"
        class="mb-6"
        prepend-text="$\Delta\text{G}^\circ_{\text{rxn}}:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        c) Would this compound dissociate into its elemental constituents at 298 K? What about if
        the temperature was increased? Justify your answer.
      </p>

      <p class="mb-n6">
        <s-textarea v-model="inputs.explanation" outlined rows="3" />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';
import NumberValue from '../NumberValue';
import STextarea from '@/common/components/STextarea.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';

export default {
  name: 'cpp1220Exam3Q3',
  components: {
    ChemicalNotationInput,
    STextarea,
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        balancedEquation: null,
        deltaG: null,
        explanation: null,
      },
    };
  },
  computed: {
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content;
    },
    molecule() {
      if (this.versionNumber.value === 1) {
        return '$\\ce{PCl5(s)}$';
      } else if (this.versionNumber.value === 2) {
        return '$\\ce{HCN(g)}$';
      } else {
        return '$\\ce{NO(g)}$';
      }
    },
    deltaH() {
      if (this.versionNumber.value === 1) {
        return '$-374.9$';
      } else if (this.versionNumber.value === 2) {
        return '$135.1$';
      } else {
        return '$82.05$';
      }
    },
    deltaS() {
      if (this.versionNumber.value === 1) {
        return '$311.78$';
      } else if (this.versionNumber.value === 2) {
        return '$201.78$';
      } else {
        return '$219.85$';
      }
    },
  },
};
</script>
